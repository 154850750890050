.uiCirclesAnimation {
  position: relative;
  width: 100%;
  height: 1123px;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 100%;
}

.leftVignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 161px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.bottomVignette {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(
    0deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.rightVignette {
  position: absolute;
  top: 0;
  right: -1px;
  width: 161px;
  height: 100%;
  background: linear-gradient(
    -90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.top {
  display: block;
}

.middle {
  position: absolute;
  z-index: 0;
  width: 100%;
  margin-top: 160px;
}

.bottom {
  position: absolute;
  z-index: -1;
  width: 100%;
  transform: translateY(-2px);
}

.grid {
  position: relative;
  z-index: 0;
}

.leftPathways,
.rightPathways {
  position: absolute;
  top: -0.5px;
  z-index: 1;
}

.leftPathways {
  left: -0.5px;
}

.rightPathways {
  right: -1px;
}

.scene {
  width: 720px;
  height: 616px;
  position: absolute;
  top: 303px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 0.5px;
}
