.hero {
  position: relative;
  overflow: hidden;
  height: fit-content;
  background: var(--color-background-inverted);
  width: 100%;
}

.top {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 836px;
  margin: 0 auto;
}

.foreground {
  z-index: 3;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.background {
  position: absolute;
  width: 1920px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.illustration {
  position: absolute;
  top: 2px;
  left: 960px;
  z-index: 1;
  transform: rotate(-90deg) translate(-130px, -90px);
}

.grid {
  position: absolute;
  top: 2px;
  z-index: 0;
}

.wrapper {
  margin-top: 128px;
  margin-right: var(--layout-gutter-s);
  margin-left: var(--layout-gutter-s);
}

.wrapperInner {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  max-width: 480px;
  margin: 0 auto;
}

.wrapperInner > * {
  text-align: center;
}

.right {
  width: 100%;
}

.heroText {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
}

.heading {
  color: var(--base-color-grey-100);
}

.heading strong {
  background: linear-gradient(
    90deg,
    var(--brand-color-blue-4, #90b7f6) 0%,
    var(--brand-color-blue-5, #6ca3fe) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading strong::after {
  display: none;
}

.subline {
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.75;
}

.cta {
  position: static;
}

.cta a {
  text-align: center;
  justify-content: center;
}

.pillars {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 42px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  margin-top: 82px;
}

.pillar {
  width: 317px;
  height: 156px;
  padding: 20px 24px;
  background: var(--color-background-inverted);
}

.pillar:nth-child(1) .pillarHeading {
  background: linear-gradient(
    270deg,
    var(--brand-color-salmon-3, #fec3ab) 79.82%,
    var(--brand-color-salmon-5, #ed8565) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pillar:nth-child(1) .pillarHeading::after {
  background: linear-gradient(
    90deg,
    var(--brand-color-salmon-5, #ed8565) 0%,
    var(--brand-color-black, #0a1b2a) 100%
  );
}

.pillar:nth-child(2) .pillarHeading {
  background: linear-gradient(
    90deg,
    var(--brand-color-green-3, #85dabb) 0%,
    var(--brand-color-green-4, #57c7a4) 22.66%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pillar:nth-child(2) .pillarHeading::after {
  background: linear-gradient(
    90deg,
    var(--brand-color-green-3, #85dabb) 0%,
    var(--brand-color-black, #0a1b2a) 100%
  );
}

.pillar:nth-child(3) .pillarHeading {
  background: linear-gradient(
    90deg,
    var(--brand-color-purple-4, #bd9eff) 0%,
    var(--brand-color-purple-6, #915eff) 29.82%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pillar:nth-child(3) .pillarHeading::after {
  background: linear-gradient(
    90deg,
    var(--brand-color-purple-5, #af8fff) 0%,
    var(--brand-color-black, #0a1b2a) 100%
  );
}

.pillarText {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.75);
}

.bottom {
  display: none;
  position: relative;
}

.topVignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(
    180deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.leftVignette {
  position: absolute;
  top: 0;
  left: calc(calc(1920px - 100vw) / 2);
  width: 80px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.bottomVignette {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(
    0deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.rightVignette {
  position: absolute;
  top: 0;
  right: calc(calc(1920px - 100vw) / 2);
  width: 80px;
  height: 100%;
  background: linear-gradient(
    -90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

@media (--small) {
  .top {
    height: 957px;
  }

  .wrapper {
    margin-top: 137px;
  }

  .illustration {
    transform: rotate(-90deg) translate(-210px, -208px);
  }
}

@media (min-width: 1104px) {
  .bottom {
    display: block;
  }

  .topInner {
    padding-bottom: 0;
  }
}

@media (--medium) {
  .top {
    height: 723px;
  }

  .topInner {
    padding-top: var(--space-6xl);
  }

  .wrapper {
    margin-top: 212px;
  }

  .wrapperInner {
    max-width: 569px;
    margin: unset;
  }

  .wrapperInner > * {
    text-align: left;
  }

  .mainBlock {
    flex-direction: row;
  }

  .left {
    width: 572px;
    text-align: left;
  }

  .illustration {
    transform: unset;
  }

  .leftVignette {
    width: 161px;
  }

  .rightVignette {
    width: 161px;
  }
}

@media (--max) {
  .leftVignette {
    left: 0;
  }

  .rightVignette {
    right: 0;
  }
}
