.root {
  --offset-top: calc(
    var(--space-xl) + var(--nav-height) + var(--banner-height)
  );

  position: relative;
}

.root.themeDark {
  --hero-background: var(--color-background-inverted);
  --hero-illustration-background: var(--color-background-light);
  --hero-color: var(--color-text-on-color);
}

.root.themeLight {
  --hero-background: var(--color-background-light);
  --hero-illustration-background: var(--color-background-inverted);
  --hero-color: var(--color-text-default);
}

.root.noTheme::after {
  content: '';
  position: absolute;
  right: var(--layout-gutter);
  bottom: 0;
  left: var(--layout-gutter);
  height: 1px;
  background: var(--color-border);
}

.headerContainer {
  position: relative;
  overflow: hidden;
  background-color: var(--hero-background);
  color: var(--hero-color);
}

.gradient {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.5;
    background: linear-gradient(
      270deg,
      transparent 0%,
      var(--hero-background) 100%
    );
  }

  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.root.flipGradient .gradient {
  transform: rotateY(180deg);
}

.headline {
  max-width: 25ch;
  font-size: fluid(32px, 60px);
  text-wrap: balance;
}

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: var(--offset-top) var(--layout-gutter)
    calc(var(--blog-hero-overlap) + var(--space-l)) var(--layout-gutter);
}

.meta {
  display: flex;
  align-items: center;
  margin-top: var(--space-s-m);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  gap: var(--space-m);

  & a {
    color: var(--color-text-tertiary);
    text-decoration: none;
  }

  & a:hover {
    color: var(--color-text-default);
  }
}

.share {
  display: flex;
  align-items: center;
  margin-top: var(--space-s-m);
  color: var(--color-text-tertiary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
  gap: var(--space-xs);
}

.shareLink {
  color: var(--color-text-tertiary);

  &:hover {
    color: var(--color-text-default);
  }
}

.shareLinkIcon {
  width: 2em;
  height: 2em;
}

.excerpt {
  display: flex;
  align-items: center;
  margin-top: var(--space-s-m);
  gap: var(--space-xs);
}

.authorImage {
  position: relative;
  width: 64px;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: var(--border-radius-default);
  clip-path: content-box;
}

@media (--medium) {
  .root {
    --hero-illustration-overlap: 0px;

    position: relative;
  }

  .header {
    width: 100%;
    max-width: calc(824px + var(--layout-gutter));
    min-height: min(100vh, 300px);
    padding-right: var(--space-m-l);
  }

  .meta {
    margin-top: 0;
  }

  .supplemental {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-s-m);
    gap: var(--space-xs);
  }
}
