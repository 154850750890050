.root {
  position: relative;
  width: 100%;
  min-height: 30vh;
  overflow: hidden;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gradient > svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file {
  --paper-color: #fff;

  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 80%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: var(--border-radius-small);
  background: var(--paper-color);
  box-shadow: 0 0 2em rgb(0 0 0 / 12.5%);
  aspect-ratio: 210 / 297;
}

.fileTitle {
  position: absolute;
  top: 3%;
  left: 5%;
  width: 90%;
  height: 40%;
}

.fileTitle > svg {
  width: 100%;
  height: auto;
}

.fileLogo {
  position: absolute;
  top: 43%;
  left: 5%;
  width: 25%;
  height: auto;
}

.fileIllustration {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 50%;
  overflow: hidden;
  border-radius: 0 3% 0 0;
  background: var(--color-background-light);
}

.fileIllustration > svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (--medium) {
  .file {
    height: 60%;
  }
}

@media (--large) {
  .file {
    height: 80%;
  }
}
