.uiScanner {
  position: absolute;
  top: 297px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%) translateY(40px);
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiScanner.inView {
  transform: translateX(-50%) translateY(0);
}

.uiBrowser {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 100px);
  transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

.uiBrowser.inView {
  transform: translate(-50%, 0);
}

.uiModal {
  transform: translateY(40px);
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiModal.inView {
  transform: translateY(0);
}

.uiCard,
.uiUptimeProgress,
.uiUptimeProxy,
.uiUptimeDelivery {
  position: absolute;
  z-index: 2;
}

.uiCard {
  top: 100px;
}

.uiCardOne {
  left: -10px;
  transform: translateY(120px) scale(0.86);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transform-origin: center;
}

.uiCardOne.inView {
  transform: translateY(0) scale(1);
}

.uiCardTwo {
  left: 50%;
  transform: translateX(-50%) translateY(120px) scale(0.86);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transform-origin: center;
}

.uiCardTwo.inView {
  transform: translateX(-50%) translateY(0) scale(1);
}

.uiCardThree {
  right: -10px;
  transform: translateY(120px) scale(0.86);
  transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transform-origin: center;
}

.uiCardThree.inView {
  transform: translateY(0) scale(1);
}

.uiCardStroke {
  transition: stroke 0.3s ease, stroke-opacity 0.3s ease;
}

.uiCardStrokeActive {
  stroke: var(--brand-color-green-5, #1bb694);
  stroke-opacity: 1;
}

.uiCardIcons {
  transform: translateY(0);
  transition: transform 0.4s ease;
}

.uiCardIcons.uiCardIconsActive {
  transform: translateY(-24px);
}

.uiCardIcons > .uiCardIconGreen {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.uiCardIcons > .uiCardIconGrey {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.uiCardIcons.uiCardIconsActive > .uiCardIconGreen {
  opacity: 1;
}

.uiCardIcons.uiCardIconsActive > .uiCardIconGrey {
  opacity: 0;
}

.uiCardCaption {
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-family: var(--font-family-headline);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0005em;
  color: var(--base-color-white);
}

.uiUptimeProgress {
  top: 361px;
  left: 40px;
  transform: translateY(40px);
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiUptimeProgress.inView {
  transform: translateY(0);
}

.uiUptimeProgressGreenTiles > rect {
  opacity: 0;
}

.uiUptimeProgressCaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 21px 16px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.uiUptimeProgressCaption__left {
  font-family: var(--font-family-headline);
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  color: var(--base-color-white);
  opacity: 0.7;
}

.uiUptimeProgressCaption__right {
  font-family: var(--font-family-body);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.18px;
  color: var(--base-color-grey-600, #5f7886);
}

.uiUptimeProxy {
  top: 465px;
  left: 40px;
  transform: translateY(40px);
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiUptimeProxy.inView {
  transform: translateY(0);
}

.uiUptimeProxyCaption {
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  padding: 21px 16px;
  font-family: var(--font-family-body);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.18px;
  color: var(--base-color-white);
  opacity: 0.7;
}

.uiUptimeProxyCircle {
  transition: x 0.5s cubic-bezier(0.34, 1, 0.64, 1),
    fill 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiUptimeProxyCircle.inView {
  x: 174;
  fill: var(--brand-color-green-5, #1bb694);
}

.uiUptimeDelivery {
  top: 361px;
  right: 40px;
  transform: translateY(40px);
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1);
}

.uiUptimeDelivery.inView {
  transform: translateY(0);
}

.uiUptimeDeliveryTiles {
  transition: transform 0.5s cubic-bezier(0.34, 1, 0.64, 1),
    y 0.5s cubic-bezier(0.34, 1, 0.64, 1);
  transform: scaleY(0);
  transform-origin: bottom;
}

.uiUptimeDeliveryTiles.inView {
  transform: scaleY(1);
}

.uiUptimeDeliveryCaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 16px;
  width: 100%;
  font-family: var(--font-family-mono);
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.uiUptimeDeliveryCaption__left {
  color: var(--base-color-white);
  opacity: 0.7;
}

.uiUptimeDeliveryCaption__right {
  color: var(--brand-color-green-5, #1bb694);
  display: flex;
  align-items: center;
  gap: 9px;
}
