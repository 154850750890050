.root {
  --offset-top: calc(
    var(--space-xl) + var(--nav-height) + var(--banner-height)
  );

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding-top: var(--offset-top);
  padding-right: var(--layout-gutter);
  padding-left: var(--layout-gutter);
}

.text {
  margin-bottom: var(--space-xl);
}

.headline {
  font-size: fluid(44px, 72px);
}

.eyebrow {
  color: var(--hero-color-secondary);
}

.media {
  aspect-ratio: 1 / 1;
  position: relative;
  z-index: 2;
  margin: calc(-1 * var(--hero-illustration-overlap)) var(--layout-gutter) 0
    var(--layout-gutter);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-inverted);
}

.media > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.callToActionCard {
  margin-top: var(--space-xl);
  padding: var(--space-l);
  border-radius: var(--border-radius-small);
  background: var(--color-background-light);
}

.callToActionNoForm {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--space-m);
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-xl-2xl);
}

.callToActionForm {
  margin-top: var(--space-xl-2xl);
}

.callToActionFormWrapper {
  margin-top: var(--space-xs);
}

.callToActionInstructions {
  color: var(--color-text-secondary);
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
}

@media (--medium) {
  .root {
    min-height: 95vh;
  }

  .text {
    width: calc(50% - var(--column-gutter) / 2);
    min-height: var(--hero-min-height, min(100vh, 500px));
    padding-right: var(--space-m-l);
  }

  .media {
    position: absolute;
    top: var(--offset-top);
    right: 0;
    bottom: 0;
    width: calc(50% - var(--column-gutter) / 2);
    margin: 0;
    margin-bottom: 0;
    border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
    aspect-ratio: unset;
  }
}

@media (--large) {
  .text {
    padding-right: var(--space-xl-2xl);
  }

  .callToActionCard {
    padding: var(--space-xl);
  }
}
