.root {
  position: relative;
  max-width: max(50vw, 450px);
  margin-right: auto;
  margin-left: auto;
  padding: var(--space-xl) var(--space-xl);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  outline: 1px solid var(--color-border);
  background: var(--color-background-default);
  color: var(--color-text-default);
  aspect-ratio: unset;
}

.formIntro {
  margin-bottom: var(--space-m-l);
  font-family: var(--font-family-body);
  font-size: var(--text-body-xl);
}

.tickList {
  margin-bottom: var(--space-l-xl);
}

.instructions {
  max-width: 55ch;
  margin-top: var(--space-m-l);
  margin-bottom: var(--space-m-l);
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
}

.inputs > * + * {
  margin-top: var(--space-s-m);
}

.actions {
  margin-top: var(--space-m-l);
}

.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--space-m-l);
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  line-height: 1.2;
}

.overlay[role='alert'] {
  background: var(--color-alert);
  color: var(--color-text-on-color);
}

.overlay:not([role='alert']) {
  background: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.overlayIcon {
  color: currentcolor;
}

.overlayText {
  max-width: 30ch;
}

.overlayText > span {
  opacity: 0.65;
}

.overlayText > p + p {
  margin-top: 0.5em;
}

.fallbackLink {
  color: currentcolor;
  text-decoration-thickness: 1px;
}

.fallbackLink:hover {
  text-decoration: none;
}
