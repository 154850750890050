.root {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);
}

.shareLabel {
  color: var(--color-text-tertiary);
}

.shareLink {
  opacity: 0.75;
  color: inherit;
}

.shareLink:hover {
  opacity: 1;
}

.shareIcon {
  width: 2rem;
  height: 2rem;
}
