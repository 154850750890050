.inputWrapper {
  position: relative;
}

.textInput {
  padding: var(--space-m);
}

.actions {
  margin-top: var(--space-s);
}

.result {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  line-height: 1;
}

.success {
  padding: var(--space-s);
  border-radius: var(--border-radius-small);
  background: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.icon {
  width: 3.5rem;
  height: auto;
}

.success .icon {
  color: currentcolor;
}

@media (--small) {
  .actions {
    position: absolute;
    top: var(--space-s);
    right: var(--space-s);
    margin: 0;
  }
}
