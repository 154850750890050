.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle {
  position: absolute;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 50%;
  opacity: 0;
}
