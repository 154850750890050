.productHero {
  position: relative;
  overflow: hidden;
  height: fit-content;
  background: var(--color-background-inverted);
  width: 100%;
}

.illustration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
}

.illustrationPackages {
  & .heading strong {
    background: linear-gradient(
      90deg,
      var(--brand-color-red-4) 0%,
      var(--brand-color-red-6) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.illustrationSafeguard {
  & .heading strong {
    background: linear-gradient(
      90deg,
      var(--brand-color-green-4) 0%,
      var(--brand-color-green-6) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.illustrationDistribution {
  & .heading strong {
    background: linear-gradient(
      90deg,
      var(--brand-color-purple-4) 0%,
      var(--brand-color-purple-6) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.top {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 616px;
  margin: 0 auto;
}

.foreground {
  z-index: 3;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background {
  position: absolute;
  width: 1920px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.grid {
  position: absolute;
  top: 2px;
  z-index: 0;
}

.wrapper {
  margin-top: 100px;
  margin-right: var(--layout-gutter-s);
  margin-left: var(--layout-gutter-s);
}

.wrapperInner {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  max-width: 737px;
  margin: 0 auto;
}

.wrapperInner > * {
  text-align: center;
}

.heroText {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.heading {
  color: var(--base-color-grey-100);
}

.heading strong {
  background: linear-gradient(
    90deg,
    var(--brand-color-blue-4, #90b7f6) 0%,
    var(--brand-color-blue-5, #6ca3fe) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading strong::after {
  display: none;
}

.subline {
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.75;
}

.cta {
  display: flex;
  justify-content: center;
}

.topVignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(
    180deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.leftVignette {
  position: absolute;
  top: 0;
  left: calc(calc(1920px - 100vw) / 2);
  width: 80px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.bottomVignette {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 161px;
  background: linear-gradient(
    0deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.rightVignette {
  position: absolute;
  top: 0;
  right: calc(calc(1920px - 100vw) / 2);
  width: 80px;
  height: 100%;
  background: linear-gradient(
    -90deg,
    var(--base-color-grey-1000, #031c2b) 0%,
    rgba(3, 28, 43, 0) 100%
  );
  z-index: 2;
}

.logosCaption {
  display: block;
  text-align: center;
  margin: 80px auto;
}

@media (--medium) {
  .top {
    height: 723px;
  }

  .foreground {
    top: 0;
    transform: translate(-50%, 0);
  }

  .wrapper {
    margin-top: 216px;
  }

  .leftVignette {
    width: 161px;
  }

  .rightVignette {
    width: 161px;
  }

  .logosCaption {
    display: none;
  }

  .illustration {
    display: block;
  }
}

@media (--max) {
  .leftVignette {
    left: 0;
  }

  .rightVignette {
    right: 0;
  }
}
