.hero {
  --hero-illustration-overlap: 0px;
}

.formMedia {
  overflow: visible;
  background: transparent;
  aspect-ratio: unset;
}

.form {
  --color-text-secondary: var(--base-color-grey-600);
  --form-background-color: var(--color-background-light);
  position: relative;
}

.text {
  max-width: 40ch;
  margin-top: var(--space-2xl);
  margin-bottom: var(--space-xl);
}

@media (--large) {
  .form {
    padding: var(--space-l-xl);
    padding-top: 0;
  }
}
