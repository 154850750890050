.media {
  aspect-ratio: unset;
}

.callToAction {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  min-height: min(80vh, 350px);
  padding: var(--space-m-l);
  color: var(--color-text-on-color);
}

.callToActionIllustration {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.callToActionIllustration > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.intro {
  max-width: 45ch;
  font-family: var(--font-family-headline);
  font-size: fluid(20px, 24px);
  letter-spacing: -0.005em;
  line-height: 1.3;
}

@media (--medium) {
  .callToAction {
    padding: var(--space-l-xl);
  }
}
