.UIContainer {
  width: fit-content;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.UIModalContainer {
  position: relative;
  top: 57px;
}